import {Identifier} from "ra-core";
export enum MemberStatuses {
  Active = "Active",
  Inactive = "Inactive",
  Pending = "Pending",
  Deleted = "Deleted",
}

export enum OrganizationStatuses {
  Active = "Active",
  Inactive = "Inactive",
  Pending = "Pending",
  Deleted = "Deleted",
}

export enum ComparableMarketplaceSources {
  VSSkybox = "VSSkybox",
  TicketEvolution = "TicketEvolution",
  ShowsOnSale = "ShowsOnSale",
  SeatGeek = "SeatGeek",
  TicketNetworkDirect = "TicketNetworkDirect",
  TradeDesk = "TradeDesk",
}

export enum InventorySourceTypes {
  VSSkybox = "VSSkybox",
  TicketEvolution = "TicketEvolution",
  ShowsOnSale = "ShowsOnSale",
  SeatGeek = "SeatGeek",
  TicketNetworkPosNext = "TicketNetworkPosNext",
  TicketNetworkPos11Api = "TicketNetworkPos11Api",
  TradeDesk = "TradeDesk",
}

export enum ExternalTenantTypes {
  Skybox = "Skybox",
  None = "None",
}

export enum OrganizationRequestStatuses {
  Approved = "Approved",
  Rejected = "Rejected",
  Pending = "Pending",
  Deleted = "Deleted",
}

export enum InvitationStatuses {
  Approved = "Approved",
  Redeemed = "Redeemed",
  Pending = "Pending",
  Deleted = "Deleted",
}

export enum ProductionStatuses {
  Active = "Active",
  Cancelled = "Cancelled",
  Postponed = "Postponed",
  Inactive = "Inactive"
}

export enum InventoryStatuses {
  Available = "Available",
  Sold = "Sold",
  Held = "Held",
  Deleted = "Deleted",
}

export enum NotificationStatuses {
  Delivered = "Delivered",
  Canceled = "Canceled",
}

export enum stockTypes {
  MobileQr = "MobileQR",
  MobileTransfer = "MobileTransfer",
  Eticket = "ETicket",
  Physical = "Physical",
}

export enum seatTypes {
  GA = "GeneralAdmission",
  PiggyBack = "Piggyback",
  Consecutive = "Consecutive",
  Odd_Even = "OddEven",
  Parking = "Parking",
  AdaSeating = "AdaSeating",
}

export const externalTenantTypesChoices = [
  { id: "Skybox", name: "Skybox" },
  { id: "None", name: "None" },
];

export const comparableMarketplaceSourcesChoices = [
  { id: "VSSkybox", name: "VSSkybox" },
  { id: "TicketEvolution", name: "TicketEvolution" },
  { id: "ShowsOnSale", name: "ShowsOnSale" },
  { id: "SeatGeek", name: "SeatGeek" },
];


export const billingCountryChoices = [
  { id: "US", name: "United States" },
  { id: "CA", name: "Canada" }
];


export const billingStateChoices = [
  { id: "AL", name: "Alabama" },
  { id: "AK", name: "Alaska" },
  { id: "AZ", name: "Arizona" },
  { id: "AR", name: "Arkansas" },
  { id: "CA", name: "California" },
  { id: "CO", name: "Colorado" },
  { id: "CT", name: "Connecticut" },
  { id: "DE", name: "Delaware" },
  { id: "FL", name: "Florida" },
  { id: "GA", name: "Georgia" },
  { id: "HI", name: "Hawaii" },
  { id: "ID", name: "Idaho" },
  { id: "IL", name: "Illinois" },
  { id: "IN", name: "Indiana" },
  { id: "IA", name: "Iowa" },
  { id: "KS", name: "Kansas" },
  { id: "KY", name: "Kentucky" },
  { id: "LA", name: "Louisiana" },
  { id: "ME", name: "Maine" },
  { id: "MD", name: "Maryland" },
  { id: "MA", name: "Massachusetts" },
  { id: "MI", name: "Michigan" },
  { id: "MN", name: "Minnesota" },
  { id: "MS", name: "Mississippi" },
  { id: "MO", name: "Missouri" },
  { id: "MT", name: "Montana" },
  { id: "NE", name: "Nebraska" },
  { id: "NV", name: "Nevada" },
  { id: "NH", name: "New Hampshire" },
  { id: "NJ", name: "New Jersey" },
  { id: "NM", name: "New Mexico" },
  { id: "NY", name: "New York" },
  { id: "NC", name: "North Carolina" },
  { id: "ND", name: "North Dakota" },
  { id: "OH", name: "Ohio" },
  { id: "OK", name: "Oklahoma" },
  { id: "OR", name: "Oregon" },
  { id: "PA", name: "Pennsylvania" },
  { id: "RI", name: "Rhode Island" },
  { id: "SC", name: "South Carolina" },
  { id: "SD", name: "South Dakota" },
  { id: "TN", name: "Tennessee" },
  { id: "TX", name: "Texas" },
  { id: "UT", name: "Utah" },
  { id: "VT", name: "Vermont" },
  { id: "VA", name: "Virginia" },
  { id: "WA", name: "Washington" },
  { id: "WV", name: "West Virginia" },
  { id: "WI", name: "Wisconsin" },
  { id: "WY", name: "Wyoming" },
  { id: "BC", name: "British Columbia" },
  { id: "MB", name: "Manitoba" },
  { id: "NB", name: "New Brunswick" },
  { id: "NL", name: "Newfoundland and Labrador" },
  { id: "NT", name: "Northwest Territories" },
  { id: "NS", name: "Nova Scotia" },
  { id: "NU", name: "Nunavut" },
  { id: "ON", name: "Ontario" },
  { id: "PE", name: "Prince Edward Island" },
  { id: "QC", name: "Quebec" },
  { id: "SK", name: "Saskatchewan" },
  { id: "YT", name: "Yukon" },
];

export enum ConfigurationValueTypes {
  String = "string",
  Number = "number",
  Date = "date",
  Json = "json",
}

export enum ProductionClassifications {
  Main = "Main",
  Parking = "Parking"
}

export enum MessageTrackingStatus
{
  Received = "Received",
  Identified = "Identified",
  Parsed = "Parsed",
  Published = "Published",
  Unidentified = "Unidentified",
  Unparsable = "Unparsable"
}

export const phoneNumberFormat = (num: string) => {
  if (!num) return "";
  // Remove all characters that are not numbers
  let newNum = num.replace(/\D/g, "");
  let x;
  if (newNum.length <= 3) {
    x = newNum;
  } else if (newNum.length > 3 && newNum.length <= 6) {
    x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6);
  } else {
    x =
      "(" +
      newNum.slice(0, 3) +
      ") " +
      newNum.slice(3, 6) +
      "-" +
      newNum.slice(6, 10);
  }
  return x;
};

export const internationalPhoneParse = (input = '') => {
  // If empty, just return
  if (!input) return '';

  // Check if first character is "+"
  const hasPlus = input[0] === '+';

  // Remove every non-digit character (except the very first if it was '+')
  let digits = input.slice(hasPlus ? 1 : 0).replace(/\D/g, '');

  // Re-add '+' if it was there
  if (hasPlus) {
    digits = '+' + digits;
  }

  return digits;
};

export const resourceUrls = {
  ClientService: process.env.REACT_APP_BE_CLIENT_SERVICE_URL ?? "",
  PricerEngine: process.env.REACT_APP_BE_PRICER_ENGINE_SERVICE_URL ?? "",
  NotificationService: process.env.REACT_APP_BE_NOTIFICATION_SERVICE_URL ?? "",
  ConfigurationService:
    process.env.REACT_APP_BE_CONFIGURATION_SERVICE_URL ?? "",
  InventoryService: process.env.REACT_APP_BE_INVENTORY_SERVICE_URL ?? "",
  PosIntegrationService:
    process.env.REACT_APP_BE_POS_INTEGRATION_SERVICE_URL ?? "",
  SchedulerService: process.env.REACT_APP_BE_SCHEDULER_SERVICE_URL ?? "",
  MappingService: process.env.REACT_APP_BE_MAPPING_SERVICE_URL ?? "",
  MarketplaceService: process.env.REACT_APP_BE_MARKETPLACE_SERVICE_URL ?? "",
  BillingService: process.env.REACT_APP_BE_BILLING_SERVICE_URL ?? "",
  AuditService: process.env.REACT_APP_BE_AUDIT_SERVICE_URL ?? "",
  EmailParsingService: process.env.REACT_APP_BE_EMAIL_PARSING_SERVICE_URL ?? "",
};

export const authConstants = {
  LogoutUrl: process.env.REACT_APP_B2C_APP_POSTLOGOUT_REDIRECT_URL,
  ClientId: process.env.REACT_APP_ADMIN_APP_CLIENT_ID,
  TenantName: process.env.REACT_APP_ADMIN_APP_TENANT_NAME,
  PolicyId: process.env.REACT_APP_ADMIN_APP_POLICY_ID,
};

export const buttonStyle: React.CSSProperties = {
  margin: "10px",
};

export const telemetryConfig = {
  appInsightsKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY,
};

export const appVersion = process.env.REACT_APP_VERSION;

export enum LogTypes {
  Verbose = "Verbose",
  Debug = "Debug",
  Information = "Information",
  Warning = "Warning",
  Error = "Error",
  Fatal = "Fatal",
}

export const comparableMarketplaceSourceChoices = [
  { id: ComparableMarketplaceSources.VSSkybox, name: ComparableMarketplaceSources.VSSkybox },
  { id: ComparableMarketplaceSources.TicketEvolution, name: ComparableMarketplaceSources.TicketEvolution },
  { id: ComparableMarketplaceSources.ShowsOnSale, name: ComparableMarketplaceSources.ShowsOnSale },
  { id: ComparableMarketplaceSources.SeatGeek, name: ComparableMarketplaceSources.SeatGeek },
  { id: ComparableMarketplaceSources.TicketNetworkDirect, name: ComparableMarketplaceSources.TicketNetworkDirect },
  { id: ComparableMarketplaceSources.TradeDesk, name: ComparableMarketplaceSources.TradeDesk },
];



export interface SourceProductionMasterMapped {
  id?: number;
  classification: string;
  masterProductionId: Identifier;
  sourceProductionKey: string;
  sourceType: string;
  overrideExistingMapping?: boolean;
}


export interface SourceVenueMasterMapped {
  id?: number;
  masterVenueId: Identifier;
  sourceVenueKey: string;
  sourceType: string;
  overrideExistingMapping?: boolean;
}

export interface SourcePerformerMasterMapped {
  id?: number;
  masterPerformerId: Identifier;
  sourcePerformerKey: string;
  sourceType: string;
  overrideExistingMapping?: boolean;
}