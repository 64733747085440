import {
  List,
  TextField,
  TextInput,
  BooleanField,
  SelectInput,
  useRecordContext,
  useDataProvider, FunctionField, DateInput, TopToolbar, FilterButton, ExportButton,

} from "react-admin";
import { sourceTypeChoices } from "../Organizations/common";
import { CustomOrganizationReferenceInput } from "../Organizations/CustomOrganizationReferenceInput";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OrganizationReferenceField from "../Organizations/OrganizationReferenceField";
import { inventoryStatusChoices } from "./common";
import {format, parseISO} from "date-fns";
import {displayAsMoney} from "../../utils/helpers";
import {Chip, Tooltip, Stack, Button} from "@mui/material";
import Box from "@mui/material/Box";
import {CustomFieldProps} from "../Common/CustomFieldProps";
import Drawer from "@mui/material/Drawer";
import {AdditionalInfo} from "./AdditionalInfo";
import {InventoryGrid} from "./InventoryGrid";


const ProductionField: FC<CustomFieldProps> = ({ source }) => {
  const record = useRecordContext();
  return record.productionId ? (
    <Link to={`/productions/?filter=${JSON.stringify({
      id: [record.productionId],
    })}`} target="_blank">
      {`${record.productionId}`}
    </Link>
  ) : null;
};

const NotesField: FC<CustomFieldProps> = ({source}) => {
  const record = useRecordContext();
  if (!record) return null;

  const { internalNotes, externalNotes } = record;
  const hasInternal = Boolean(internalNotes);
  const hasExternal = Boolean(externalNotes);

  return (
      <Stack direction="column" spacing={1}>
        {hasInternal && (
            <Tooltip title={internalNotes} placement="left-start">
              <Chip label="Internal" color="primary" size="small" clickable/>
            </Tooltip>
        )}
        {hasExternal && (
            <Tooltip title={externalNotes} placement="left-start">
              <Chip label="External" color="secondary" size="small" clickable/>
            </Tooltip>
        )}
      </Stack>
  );
};

const AttributesAndTagsField: FC<CustomFieldProps>  = ({source}) => {
  const record = useRecordContext();
  if (!record) return null;

  const { attributes, inventoryTags } = record;

  const hasAttributes = Array.isArray(attributes) && attributes.length > 0;
  const hasTags = Array.isArray(inventoryTags) && inventoryTags.length > 0;
  
  const renderTooltipList = (items: any) => (
      <Box>
        {items.map((item: any, index: any) => (
            <Box key={index}>{item}</Box>
        ))}
      </Box>
  );

  return (
      <Stack direction="column" spacing={1}>
        {hasAttributes && (
            <Tooltip title={renderTooltipList(attributes)} placement="right-start">
              <Chip
                  label="Attributes"
                  color="primary"
                  size="small"
                  sx={{ cursor: 'pointer' }}
              />
            </Tooltip>
        )}
        {hasTags && (
            <Tooltip title={renderTooltipList(inventoryTags)} placement="right-start">
              <Chip
                  label="Tags"
                  color="secondary"
                  size="small"
                  sx={{ cursor: 'pointer' }}
              />
            </Tooltip>
        )}
      </Stack>
  );
};


const inventoryFilter = [
  <CustomOrganizationReferenceInput
    source="organizationKey"
    label="POS Org"
    alwaysOn
  />,
  <TextInput source="sourceProductionKey" />,
  <TextInput source="productionName" />,
  <TextInput source="productionId" label="Production ID" />,
  <DateInput
      label=" Production Date From"
      source="localDateTime_gte"
      />,
  <DateInput
      label="Production Date To"
      source="localDateTime_lte"
  />,
  <TextInput source="venueName" />,
  <SelectInput source="sourceType" choices={sourceTypeChoices} />,
  <SelectInput source="inventoryStatus" choices={inventoryStatusChoices} />,

];

export const InventoryList = () => {
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [open, setOpen] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState({} as any);
  const dataProvider = useDataProvider();
  useEffect(() => {
    dataProvider
      .getList("organizations", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        setOrganizations(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dataProvider]);
  
  const ListActions = () => (
      <TopToolbar>
        <FilterButton/>
        <ExportButton/>
      </TopToolbar>
  );

  const ViewAdditionalInfo = () => {
    const record = useRecordContext();
    const handleAdditionalInfoClick = () => {

      setSelectedRecord(record);
      setOpen(true);
    };
    return (
        <Button onClick={() => handleAdditionalInfoClick()}>Additional Info</Button>
    );
  };
  
  
  return (
      <>
    <List filters={inventoryFilter} empty={false} actions={<ListActions />}
          sx={{
            '& .MuiToolbar-root': {
              flexDirection: 'row-reverse',
            },
          }}>
        <InventoryGrid size={"small"}
                  sx={{
                    '& .RaDatagrid-tableWrapper': {
                      overflowX: 'auto',
                    },
                  }}>
          <TextField source="id" />
          <OrganizationReferenceField organizations={organizations} source="Pos Org"/>
          <ProductionField source="productionId"/>
          <TextField source="productionName" />
          <FunctionField
              source="localDateTime"
              label="Local DateTime"
              render={(record: { localDateTime: string }) =>
                  format(parseISO(record.localDateTime), "EEEE MMMM d, yyyy h:mm a")}
          />
          <TextField source="venueName" />
          <TextField source="section" />
          <TextField source="row" />
          <TextField source="quantity" />
          <TextField source="startSeat" sortable={false}/>
          <TextField source="endSeat" sortable={false}/>
          <TextField source="stockType" />
          <TextField source="seatType" sortable={false}/>
          <FunctionField
              source="costPrice"
              label="Cost Price"
              sortable={false}
              render={(record: { costPrice: number }) =>
                  displayAsMoney(record.costPrice)}
          />
          <FunctionField
              source="listPrice"
              label="List Price"
              render={(record: { listPrice: number }) =>
                  displayAsMoney(record.listPrice)}
          />
          <FunctionField
              source="soldPrice"
              label="Sold Price"
              sortable={false}
              render={(record: { soldPrice: number }) =>
                  displayAsMoney(record.soldPrice)}
          />
          <TextField source="splitRule" sortable={false}/>
          <BooleanField source="isInHand" />
          <BooleanField source="isParking" />
          <BooleanField source="isShared" sortable={false}/>
          <TextField source="inventoryStatus" sortable={false}/>
          <TextField source="validSplits" sortable={false}/>
          <TextField source="sourceKey" sortable={false} label="Pos Ticket Group Id"/>
          <TextField source="sourceProductionKey" sortable={false}/>
          <TextField source="sourceType" sortable={false}/>
          <TextField source="inHandDate" />
          <NotesField source="notes"/>
          <AttributesAndTagsField source="Attributes and tags"/>
          <ViewAdditionalInfo/>
        </InventoryGrid>
     
    </List>
    <Drawer open={open} onClose={() => setOpen(false)} anchor={"right"} PaperProps={{
      sx: { width: "50%" },
    }}>
      <AdditionalInfo record={selectedRecord}/>
    </Drawer>
  </>
  );
};
