import React from 'react';
import {
    List,
    TextField,
    ListProps,
    SearchInput,
    SelectInput,
    TopToolbar,
    ExportButton,
    BooleanField, BooleanInput, FilterButton, useRecordContext, useRefresh
} from "react-admin";
import { ThemeProvider } from '@mui/material/styles';

import {SourceVenuePagination} from './VenuePagination';


import { Identifier } from "ra-core";

import { PriorityGrid } from "./PriorityGrid";
import { MappingTheme } from "./MappingTheme";

import {Venue} from "./Venue";
import {MasterVenue} from "./MasterVenue";
import {comparableMarketplaceSourceChoices} from "../../utils/constants";
import {MasterVenueChild} from "./MasterVenueChild";
import Drawer from "@mui/material/Drawer";
import {SourceVenueMappingAudits} from "./Audits";
import {Button} from "@mui/material";

const sxFilters = { width: '13rem' };
type ExpandRowProps = {
    id: Identifier;
    record: Venue;
    resource: string;
};

const MainListActions = () => (
    <TopToolbar>
        <FilterButton />
        <ExportButton />
    </TopToolbar>
)

export const VenueMappingsList = (props: ListProps<MasterVenue>) => (
    <ThemeProvider theme={MappingTheme}>
        <VenueMappingsListClean {...props} />
    </ThemeProvider>
);

export const VenueMappingsListClean = (props: ListProps<MasterVenue>) => {
    const [open, setOpen] = React.useState(false);
    const [selectedRecord, setSelectedRecord] = React.useState({} as any);
    const refresh = useRefresh();
    const ViewAudits = () => {
        const record = useRecordContext();
        const handleAuditClick = () => {

            setSelectedRecord(record);
            setOpen(true);
        };
        return (
            <Button onClick={() => handleAuditClick()}>View Audits</Button>
        );
    };

    const venueMappingsFilter = [
        <SearchInput
            resettable
            source="searchText_fts"
            sx={sxFilters}
            alwaysOn />,
        <SelectInput
            sx={sxFilters}
            source="sourceType"
            alwaysOn
            label="Source Type"
            emptyText={'All'}
            emptyValue={''}
            choices={comparableMarketplaceSourceChoices} />,
        <BooleanInput 
            source="isMapped" label="Is Mapped"
            defaultValue={"false"}
            parse={(value) => value.toString()}
            format={(value) => value == 'true'}
        />
        
    ];
    
    
    const expandRow = ({ id, record }: ExpandRowProps) => {
       
        return <ThemeProvider theme={MappingTheme}>
            {
                    <MasterVenueChild
                        sourceVenueKey={record.sourceKey}
                        sourceType={record.sourceType}
                        state={record.state}
                        name={record.name}
                        mappedMasterVenueId={record.mappedMasterVenueId}
                        isMapped={record.isMapped}
                        refresh={refresh}
                    />
            }
        </ThemeProvider>;
    };

    return (
        <>
            <List title="Venue Mappings"
                actions={<MainListActions />}
                filters={venueMappingsFilter} 
                filterDefaultValues={{ isMapped: "false"}}
                sort={{ field: 'name', order: 'ASC' }}
                resource={'source-venues'}
                perPage={50}
                pagination={<SourceVenuePagination />}
            >
                <PriorityGrid
                    expandSingle={true}
                    expand={expandRow}
                    bulkActionButtons={false}
                    optimized
                >
                    <TextField source="id" sortable={false} />
                    <TextField source="name" sortable={true} />
                    <TextField source="address" sortable={true} />
                    <TextField source="city" sortable={true} />
                    <TextField source="state" sortable={true} />
                    <TextField source="postalCode" sortable={true} />
                    <TextField source="sourceKey" sortable={true} />
                    <TextField source="sourceType" sortable={true} />
                    <BooleanField source={'isMapped'} sortable={true} />
                    <ViewAudits/>
                </PriorityGrid>
            </List>
            <Drawer open={open} onClose={() => setOpen(false)} anchor={"right"} PaperProps={{
                sx: { width: "50%" },
            }}>
                <SourceVenueMappingAudits record={selectedRecord}/>
            </Drawer>
        </>
    );
};
