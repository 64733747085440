import { FC } from "react";
import { ConfigurationValueTypes } from "../../utils/constants";
import { DateInput, TextInput, useRecordContext } from "react-admin";
import {JsonInput} from "react-admin-json-view";

export const configurationValueTypeChoices = [
  { id: ConfigurationValueTypes.String, name: ConfigurationValueTypes.String },
  { id: ConfigurationValueTypes.Number, name: ConfigurationValueTypes.Number },
  { id: ConfigurationValueTypes.Date, name: ConfigurationValueTypes.Date },
  { id: ConfigurationValueTypes.Json, name: ConfigurationValueTypes.Json },
];


 interface ValueInputFieldProps {
  type?: string;
}
export const ValueInputField: FC<ValueInputFieldProps> = ({ type }) => {
  const record = useRecordContext();
  let typeToCheck = type ? type : record.type;
  
  switch (typeToCheck) {
    case ConfigurationValueTypes.String:
      return <TextInput source="value" label="Value" required/>
    case ConfigurationValueTypes.Number:  
      return <TextInput source="value" label="Value" type="number" required />
    case ConfigurationValueTypes.Date:  
      return <DateInput source="value" label="Value" required/>
    case ConfigurationValueTypes.Json:
      return <JsonInput source="value" label="Value" jsonString={true}
                        reactJsonOptions={{
                          // Props passed to react-json-view
                          name: null,
                          collapsed: true,
                          enableClipboard: false,
                          displayDataTypes: false,
                        }}/>
    default:
      return <TextInput source="value" label="Value" required/>
  }
};