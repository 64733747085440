import {SelectArrayInput, SelectInput, useDataProvider} from "react-admin";
import React, {FC, useEffect, useState} from "react";
import {CustomFieldProps} from "../../Common/CustomFieldProps";

export const CountryChoices: FC<CustomFieldProps> = ({source = "country", label = "Country", type }) => {
    const dataProvider = useDataProvider();
    const [countryChoices, setCountryChoices] = useState([] as any);
    useEffect(() => {
        const fetchCountries = async () => {
            const resp = await dataProvider.getCountries();
            const countries = resp.json.value;
            const countriesParsed = JSON.parse(countries);
            
            setCountryChoices(countriesParsed);
        }
        fetchCountries();
    },[dataProvider]);
    return (
        type == "array" ?
            <SelectArrayInput label={label} source={source} choices={countryChoices} />
            :
        <SelectInput
            source={source}
            choices={countryChoices}
            defaultValue="United States"
            required
        />
    );
    }