import {DataProvider, fetchUtils} from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import {resourceUrls} from "./constants";


export interface StripeProduct {
    id: string;
    name: string;
}

export interface StripePrice {
    id: string;
    name: string;
}

export interface StripeSubscription {
    id: string;
    name: string;
}

export interface StripeSubscriptionItem {
    id: string;
    name: string;
}

const customDataProvider = (
    token: string,
    baseUrl: string,
    setIsTokenExpired?: React.Dispatch<React.SetStateAction<boolean>>
): DataProvider => {
    const be_base_url: string = baseUrl;
    const httpClient = async (url: string, options: any = {}) => {
        const headers =
            options.headers instanceof Headers
                ? options.headers
                : new Headers(options.headers || {});
        if (!headers.has("Accept")) {
            headers.set("Accept", "application/json");
        }
        if (token) {
            if (isTokenExpired(token)) {
                if (setIsTokenExpired) setIsTokenExpired(true);
            }
            headers.set("Authorization", `Bearer ${token}`);
        }
        const fetchOptions = {...options, headers};
        return fetchUtils.fetchJson(url, fetchOptions);
    };
    const dataProvider = simpleRestProvider(be_base_url, httpClient);

    const sendInvitation = async (params: any) => {
        const url = `${be_base_url}/Invitations/sendInvitation`;
        return await httpClient(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params.data),
        });
    };
    const resendInvitation = async (params: any) => {
        const url = `${be_base_url}/Invitations/ResendInvitation`;
        return await httpClient(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params.data),
        });
    };

    const sendResettPassword = async (params: any) => {
        const url = `${be_base_url}/members/ForceResetPassword/${params.data.id}`;
        return await httpClient(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: {},
        });
    };

    const approveOrgRequest = async (params: any) => {
        const url = `${be_base_url}/Organization-Requests/ApproveOrgRequest`;
        return await httpClient(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params.data),
        });
    };

    const getPointsOfSale = async (params: any) => {
        const url = `${resourceUrls.PosIntegrationService}/poscredential/pointofsales/${params.orgKey}`;
        return await httpClient(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
    };

    const getMarketplaces = async (params: any) => {
        const url = `${resourceUrls.MarketplaceService}/marketplacecredential/marketplaces/${params.orgKey}`;
        return await httpClient(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
    };

    const initializeScheduleJob = async (params: any) => {
        const url = `${resourceUrls.SchedulerService}/instances/${params.data.id}/initialize`;
        return await httpClient(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    const instancePauseResume = async (params: any) => {
        const action = params.data.action === 'Resume' ? 'resume' : 'pause';
        const url = `${resourceUrls.SchedulerService}/instances/${params.data.id}/${action}`;
        return await httpClient(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    const getLoggingServiceKeys = async () => {
        const url = `${resourceUrls.ConfigurationService}/logging/serviceKeys`;
        return await httpClient(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
    }
    const deleteOverride = async (params: any) => {
        const url = `${resourceUrls.ConfigurationService}/logging/${params.serviceKey}`;
        return await httpClient(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    const deleteProductionPriorityMapping = async (params: any) => {
        const url = `${resourceUrls.InventoryService}/production-mapping/mapping-priority`;
        return await httpClient(url, {
            method: "DELETE",
            body: JSON.stringify(params.data),
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    const removeVenueMapping = async (params: any) => {
        const url = `${resourceUrls.MappingService}/source-venue-mappings/remove-mapping`;
        return await httpClient(url, {
            method: "POST",
            body: JSON.stringify(params.data),
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    const removePerformerMapping = async (params: any) => {
        const url = `${resourceUrls.MappingService}/source-performer-mappings/remove-mapping`;
        return await httpClient(url, {
            method: "POST",
            body: JSON.stringify(params.data),
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    const deleteSectionPriorityMapping = async (params: any) => {
        const url = `${resourceUrls.InventoryService}/section-mapping/mapping-priority`;
        return await httpClient(url, {
            method: "DELETE",
            body: JSON.stringify(params.data),
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    const getStripeProducts = async (): Promise<StripeProduct[]> => {
        const url = `${resourceUrls.BillingService}/stripe-products`;
        const response = await httpClient(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.json;
    };

    const getStripePrices = async (): Promise<StripePrice[]> => {
        const url = `${resourceUrls.BillingService}/stripe-prices`;
        const response = await httpClient(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.json;
    };

    const getSubscriptions = async (): Promise<StripeSubscription[]> => {
        const url = `${resourceUrls.BillingService}/stripe-subscriptions`;
        const response = await httpClient(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.json;
    };

    const getSubscriptionItems = async (subscriptionId: string): Promise<StripeSubscriptionItem[]> => {
        const url = `${resourceUrls.BillingService}/stripe-subscription-items/${subscriptionId}`;
        const response = await httpClient(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.json;
    };


    const removeSourceProductionMapping = async (params: any) => {
        const url = `${resourceUrls.MappingService}/source-production-mappings/remove-mapping`;
        return await httpClient(url, {
            method: "POST",
            body: JSON.stringify(params.data),
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    const getSourceProductionMappingAudits = async (params: any) => {
        const url = `${resourceUrls.MappingService}/source-production-mappings/audits?SourceProductionKey=${params.SourceProductionKey}&SourceType=${params.SourceType}&Classification=${params.Classification}`;
        const response =  await httpClient(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.json;
    }

    const getSourcePerformerMappingAudits = async (params: any) => {
        const url = `${resourceUrls.MappingService}/source-performer-mappings/audits?SourcePerformerKey=${params.SourcePerformerKey}&SourceType=${params.SourceType}`;
        const response =  await httpClient(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.json;
    }

    const getSourceVenueMappingAudits = async (params: any) => {
        const url = `${resourceUrls.MappingService}/source-venue-mappings/audits?SourceVenueKey=${params.SourceVenueKey}&SourceType=${params.SourceType}`;
        const response =  await httpClient(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.json;
    }
    
    const syncBillingAccount = async (params: any) => {
        const url = `${resourceUrls.BillingService}/billing-organizations/synchronize`;
        return await httpClient(url, {
            method: "POST",
            body: JSON.stringify(params.data),
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    const syncManyBillingAccounts = async (params: any) => {
        const url = `${resourceUrls.BillingService}/billing-organizations/synchronize-many`;
        return await httpClient(url, {
            method: "POST",
            body: JSON.stringify(params.data),
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    const getOrganizations = async () => {
        const url = `${resourceUrls.ClientService}/organizations?filter=%7B%7D&range=%5B0%2C99998%5D&sort=%5B%22id%22%2C%22DESC%22%5D`;
        const response = await httpClient(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.json;
    };
    const getTemplateProductions = async (params:any) => {
        const url = `${resourceUrls.PricerEngine}/pricing-templates/productions?filter=${JSON.stringify(params.filter)}&sort=${JSON.stringify(params.sort)}&range=${JSON.stringify(params.range)}`;
        const response = await httpClient(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.json;
    };

    const rejectCustomerProvidedMapping = async (id: string, payload:any) => {
        const url = `${resourceUrls.MappingService}/customer-provided-mappings/reject/${id}`;
        return await httpClient(url, {
            method: "PUT",   
            body: JSON.stringify(payload),        
            headers: {
                "Content-Type": "application/json",
            },
        });
    }
    const mapCustomerProvidedMapping = async ( payload:any) => {
        const url = `${resourceUrls.MappingService}/customer-provided-mappings/approve`;
        return await httpClient(url, {
            method: "POST",   
            body: JSON.stringify(payload),        
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    const getCountries = async () => {
        const url = `${resourceUrls.ConfigurationService}/configurations/countryChoices`;
        return await httpClient(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    const getStates = async () => {
        const url = `${resourceUrls.ConfigurationService}/configurations/stateChoices`;
        return await httpClient(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    const reprocessMessage = async (params: any) => {
        const url = `${resourceUrls.EmailParsingService}/message-tracking/reprocess`;
        return await httpClient(url, {
            method: "POST",
            body: JSON.stringify(params.data),
            headers: {
                "Content-Type": "application/json",
            },
        });
    }
    
    return {
        ...dataProvider,
        sendInvitation,
        resendInvitation,
        approveOrgRequest,
        sendResettPassword,
        getPointsOfSale,
        getMarketplaces,
        initializeScheduleJob,
        instancePauseResume,
        getLoggingServiceKeys,
        deleteOverride,
        deleteProductionPriorityMapping,
        deleteSectionPriorityMapping,
        syncBillingAccount,
        syncManyBillingAccounts,
        getStripeProducts,
        getStripePrices,
        getSubscriptions,
        getSubscriptionItems,        
        removeSourceProductionMapping,
        getSourceProductionMappingAudits,
        getOrganizations,
        getTemplateProductions,
        rejectCustomerProvidedMapping,
        mapCustomerProvidedMapping,
        removeVenueMapping,
        removePerformerMapping,
        getSourcePerformerMappingAudits,
        getSourceVenueMappingAudits,
        getCountries,
        getStates,
        reprocessMessage
    };
};

const isTokenExpired = (token: string) => {
    const tokenParts = token.split(".");
    if (tokenParts.length !== 3) {
        return false;
    }

    const decodedPayload = JSON.parse(atob(tokenParts[1]));
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const tokenExpirationTimeInSeconds = decodedPayload.exp;

    return currentTimeInSeconds >= tokenExpirationTimeInSeconds;
};

export default customDataProvider;
