import { FaMailBulk } from "react-icons/fa";
import {MessageTrackingList} from "../components/MessageTracking/MessageTrackingList";

const MessageTracking = {
    name: "message-tracking",
    icon: FaMailBulk,
    list: MessageTrackingList,
    menuLabel: "Message Tracking",
};

export default MessageTracking;