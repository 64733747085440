// in src/admin/index.tsx
import {
    Admin,
    DataProvider,
    Resource,
    combineDataProviders
} from "react-admin";
import { CustomLayout } from "./layout";
import CircularProgress from "@mui/material/CircularProgress";
import SignInPage from "../components/auth/SignInPage";
import { useMsal } from "@azure/msal-react";
import {
    AccountInfo,
    InteractionRequiredAuthError,
    IPublicClientApplication,
} from "@azure/msal-browser";
import customDataProvider from "../utils/customDataProvider";
import React, { useState, useEffect } from "react";
import simpleRestProvider from "ra-data-simple-rest";
import { defaultTheme } from "react-admin";
import Organizations from "../resources/organizations";
import Invitations from "../resources/invitations";
import OrganizationRequests from "../resources/organizationrequests";
import Roles from "../resources/roles";
import Permissions from "../resources/permissions";
import ActiveMarketEvents from "../resources/activemarketevents";
import Notifications from "../resources/notifications";
import Configurations from "../resources/configurations";
import Settings from "../resources/settings";
import Productions from "../resources/productions";
import Performers from "../resources/performers";
import Venues from "../resources/venues";
import Inventory from "../resources/inventory";
import { extractToken } from "../utils/general";
import { resourceUrls } from "../utils/constants";
import PricingGroups from "../resources/pricing_groups";
import Members from "../resources/members";
import Footer from "../components/Common/Footer";
import { getEnvironment } from "../utils/helpers";
import Schedules from "../resources/schedules";
import ProductionMappings from "../resources/productionMappings";
import ScheduleInstances from "../resources/scheduleinstances";
import schedulerCustomDataProvider from "../utils/schedulerCustomDataProvider";
import ProductionAssignments from "../resources/production_assignments";
import NamespaceOverrides from "../resources/namespaceoverrides";
import NamespaceLoggingDefaults from "../resources/loggingdefaults";
import productionMappingsCustomDataProvider from "../utils/productionMappingsCustomDataProvider";
import VenueSectionMappings from "../resources/venuesectionmappings";
import SectionMappings from "../resources/sectionmappings";
import StripeConfigurations from "../resources/stripeConfigurations";
import BillingOrganizations from "../resources/billingOrganizations";
import OrganizationPriceMode from "../resources/organizationPriceMode";
import PricerOrganizationInventoryAudits from "../resources/pricerOrganizationInventoryAudits";
import PricingGroupAudits from "../resources/pricingGroupAudits";
import PricingTemplateAudits from "../resources/pricingTemplateAudits";
import ProductionAssignmentAudits from "../resources/productionAssignmentAudits";
import OrganizationPricingDefaultsAudits from "../resources/organizationPricingDefaultsAudits";
import OrganizationPricingSettingAudits from "../resources/organizationPricingSettingAudits";
import ClientUserAudits from "../resources/clientUserAudits";
import OrganizationAudits from "../resources/organizationAudits";
import UserInvitationAudits from "../resources/userInvitationAudits";
import ResetPasswordAudits from "../resources/resetPasswordAudits";
import ChangedPasswordAudits from "../resources/changedPasswordAudits";
import PosOrganizationAudits from "../resources/posOrganizationAudits";
import PosCredentialAudits from "../resources/posCredentialAudits";
import SourceProductionMappings from "../resources/sourceProductionMappings";
import PricingTemplates from "../resources/pricingTemplates";
import OrgBusinessSummary from "../resources/orgBusinessSummary";
import VenueMappings from "../resources/venueMappings";
import PerformerMappings from "../resources/performerMappings";
import Features from "../resources/features";
import MailboxAssignments from "../resources/mailboxAssignments";
import BillingTransactions from "../resources/billingTransactions";
import MessageTracking from "../resources/messageTracking";

const theme = {
    ...defaultTheme,
    components: {
        ...defaultTheme.components,
        MuiInputBase: {
            styleOverrides: {
                root: {
                    width: "20vw",
                },
            },
        },
    },
};

async function initialize(
    account: AccountInfo,
    instance: IPublicClientApplication,
    baseUrl: string,
    setIsTokenExpired?: any
): Promise<DataProvider<string> | null> {
    try {
        const token = await extractToken(account, instance);
        return customDataProvider(token, baseUrl, setIsTokenExpired);
    } catch (error) {
        instance.logout();
        return null;
    }
}

async function initializeProductionMappings(
    account: AccountInfo,
    instance: IPublicClientApplication,
    baseUrl: string,
    setIsTokenExpired?: any
): Promise<DataProvider<string> | null> {
    try {
        const token = await extractToken(account, instance);
        return productionMappingsCustomDataProvider(token, baseUrl, setIsTokenExpired);
    } catch (error) {
        instance.logout();
        return null;
    }
}

async function initializeScheduler(
    account: AccountInfo,
    instance: IPublicClientApplication,
    baseUrl: string,
    setIsTokenExpired?: any
): Promise<DataProvider<string> | null> {
    try {
        const token = await extractToken(account, instance);
        return schedulerCustomDataProvider(token, baseUrl, setIsTokenExpired);
    } catch (error) {
        instance.logout();
        return null;
    }
}

interface AdminWrapperProps {
    account: AccountInfo;
    instance: IPublicClientApplication;
}

const AdminWrapper: React.FC<AdminWrapperProps> = ({ instance, account }) => {
    const clientServiceDataProviderDefault = simpleRestProvider(
        resourceUrls.ClientService
    );
    const pricerEngineServiceDataProviderDefault = simpleRestProvider(
        resourceUrls.PricerEngine
    );
    const notificationServiceDataProviderDefault = simpleRestProvider(
        resourceUrls.NotificationService
    );
    const configurationServiceDataProviderDefault = simpleRestProvider(
        resourceUrls.ConfigurationService
    );
    const inventoryServiceDataProviderDefault = simpleRestProvider(
        resourceUrls.InventoryService
    );
    const schedulerServiceDataProviderDefault = simpleRestProvider(
        resourceUrls.SchedulerService
    );

    const productionMappingsServiceDataProviderDefault = simpleRestProvider(
        resourceUrls.MappingService
    );

    const sectionMappingServiceDataProviderDefault = simpleRestProvider(
        resourceUrls.MappingService
    );

    const billingServiceDataProviderDefault = simpleRestProvider(
        resourceUrls.BillingService
    );

    const auditServiceDataProviderDefault = simpleRestProvider(
        resourceUrls.AuditService
    );

    const emailParsingServiceDataProviderDefault = simpleRestProvider(
        resourceUrls.EmailParsingService
    );

    const [clientServiceDataProvider, setClientServiceDataProvider] = useState<
        DataProvider<string>
    >(clientServiceDataProviderDefault);
    const [pricerEngineServiceDataProvider, setPricerEngineServiceDataProvider] =
        useState<DataProvider<string>>(pricerEngineServiceDataProviderDefault);
    const [notificationServiceDataProvider, setNotificationServiceDataProvider] =
        useState<DataProvider<string>>(notificationServiceDataProviderDefault);
    const [
        configurationServiceDataProvider,
        setConfigurationServiceDataProvider,
    ] = useState<DataProvider<string>>(configurationServiceDataProviderDefault);
    const [inventoryServiceDataProvider, setInventoryServiceDataProvider] =
        useState<DataProvider<string>>(inventoryServiceDataProviderDefault);
    const [schedulerServiceDataProvider, setSchedulerServiceDataProvider] =
        useState<DataProvider<string>>(schedulerServiceDataProviderDefault);
    const [mappingServiceDataProvider, setMappingServiceDataProvider] =
        useState<DataProvider<string>>(productionMappingsServiceDataProviderDefault);
    const [sectionMappingServiceDataProvider, setSectionMappingServiceDataProvider] =
        useState<DataProvider<string>>(sectionMappingServiceDataProviderDefault);
    const [billingServiceDataProvider, setBillingServiceDataProvider] =
        useState<DataProvider<string>>(billingServiceDataProviderDefault);
    const [auditServiceDataProvider, setAuditServiceDataProvider] =
        useState<DataProvider<string>>(auditServiceDataProviderDefault);
    const [emailParsingServiceDataProvider, setEmailParsingServiceDataProvider] =
        useState<DataProvider<string>>(emailParsingServiceDataProviderDefault);

    const [isTokenSet, setIsTokenSet] = useState<boolean>(false);
    const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);

    const dataProviders = combineDataProviders((resource) => {
        switch (resource) {
            case "activemarketevents":
            case "pricing-groups":
            case "production-assignments":
            case "organization-pricing-setting":
            case "pricing-templates":
            case "pricing-templates/productions":
            case "org-business-summary":
                return pricerEngineServiceDataProvider;
            case "notifications":
                return notificationServiceDataProvider;
            case "configurations":
            case "settings":
            case "logging":
            case "logging/defaults":
                return configurationServiceDataProvider;
            case "venues":
            case "performers":
            case "productions":
            case "pos-inventory":
                return inventoryServiceDataProvider;
            case "instances":
            case "schedules":
                return schedulerServiceDataProvider
            case "priority-production":
            case "production-mappings":
            case "master-productions":
            case "master-performer":
            case "source-productions":
            case "source-production-mappings":
            case "source-venue-mappings":
            case "source-performer-mappings":
            case "master-production-mappings":
            case "customer-provided-mappings":
            case "source-venues":
            case "source-performers":
                return mappingServiceDataProvider
            case "section-mapping-venue":
            case "section-mapping-section":
            case "master-venue-section":
            case "master-venue":
                return sectionMappingServiceDataProvider;
            case "stripe-configurations":
            case "billing-organizations":
            case "billing-transactions":    
                return billingServiceDataProvider;
            case "pricer-organization-inventory-audits":
            case "pricing-group-audits":
            case "pricing-template-audits":
            case "production-assignment-audits":
            case "organization-pricing-defaults-audits":
            case "organization-pricing-setting-audits":
            case "client-user-audits":
            case "organization-audits":
            case "user-invitation-audits":
            case "reset-password-audits":
            case "changed-password-audits":
            case "pos-organization-audits":
            case "pos-credential-audits":
                return auditServiceDataProvider;
            case "mailbox-assignments":
            case "message-tracking":
                return emailParsingServiceDataProvider
            default:
                return clientServiceDataProvider;
        }
    });
    const envString = getEnvironment();

    useEffect(
        () => {
            if (envString) {
                document.title = `[${envString}] PRQX Admin`;
            } else {
                document.title = `PRQX Admin Portal`;
            }

            if (isTokenExpired) {
                instance.logout();
            }

            const initializeDataProvider = async () => {
                try {
                    const client = await initialize(
                        account,
                        instance,
                        resourceUrls.ClientService,
                        setIsTokenExpired
                    );
                    setClientServiceDataProvider(
                        client ?? clientServiceDataProviderDefault
                    );

                    const pricer = await initialize(
                        account,
                        instance,
                        resourceUrls.PricerEngine,
                        setIsTokenExpired
                    );
                    setPricerEngineServiceDataProvider(
                        pricer ?? pricerEngineServiceDataProviderDefault
                    );

                    const notification = await initialize(
                        account,
                        instance,
                        resourceUrls.NotificationService,
                        setIsTokenExpired
                    );
                    setNotificationServiceDataProvider(
                        notification ?? notificationServiceDataProviderDefault
                    );

                    const config = await initialize(
                        account,
                        instance,
                        resourceUrls.ConfigurationService,
                        setIsTokenExpired
                    );
                    setConfigurationServiceDataProvider(
                        config ?? configurationServiceDataProviderDefault
                    );

                    const inv = await initialize(
                        account,
                        instance,
                        resourceUrls.InventoryService,
                        setIsTokenExpired
                    );

                    setInventoryServiceDataProvider(
                        inv ?? inventoryServiceDataProviderDefault
                    );

                    const sched = await initializeScheduler(
                        account,
                        instance,
                        resourceUrls.SchedulerService,
                        setIsTokenExpired
                    );
                    setSchedulerServiceDataProvider(
                        sched ?? schedulerServiceDataProviderDefault
                    );

                    const mappingDataProviderInit = await initializeProductionMappings(
                        account,
                        instance,
                        resourceUrls.MappingService,
                        setIsTokenExpired
                    );
                    setMappingServiceDataProvider(
                        mappingDataProviderInit ?? productionMappingsServiceDataProviderDefault
                    );

                    const sectionMap = await initialize(
                        account,
                        instance,
                        resourceUrls.MappingService,
                        setIsTokenExpired
                    );
                    setSectionMappingServiceDataProvider(
                        sectionMap ?? sectionMappingServiceDataProviderDefault
                    );

                    const billing = await initialize(
                        account,
                        instance,
                        resourceUrls.BillingService,
                        setIsTokenExpired
                    );
                    setBillingServiceDataProvider(
                        billing ?? billingServiceDataProviderDefault
                    );

                    const audit = await initialize(
                        account,
                        instance,
                        resourceUrls.AuditService,
                        setIsTokenExpired
                    );
                    setAuditServiceDataProvider(
                        audit ?? auditServiceDataProviderDefault
                    );

                    const mailboxAssignment = await initialize(
                        account,
                        instance,
                        resourceUrls.EmailParsingService,
                        setIsTokenExpired
                    );
                    setEmailParsingServiceDataProvider(
                        mailboxAssignment ?? emailParsingServiceDataProviderDefault
                    );

                    setIsTokenSet(true);
                } catch (error) {
                    if (
                        error instanceof InteractionRequiredAuthError &&
                        instance.getActiveAccount()
                    ) {
                        await instance.acquireTokenRedirect({ scopes: ["openid"] });
                    }
                }
            };

            initializeDataProvider().catch(console.error);
        }, // eslint-disable-next-line
        [account, instance, isTokenExpired]
    );

    if (!account || !dataProviders || !isTokenSet) {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "900px",
                }}
            >
                <div>
                    <CircularProgress size="5rem" />
                </div>
            </div>
        );
    }

    return (
        <>
            <Admin
                dataProvider={dataProviders}
                layout={CustomLayout}
                loginPage={SignInPage}
                theme={theme}
            >
                <Resource {...Organizations} />
                <Resource {...Members} />
                <Resource {...Invitations} />
                <Resource {...OrganizationRequests} />
                <Resource {...Roles} />
                <Resource {...Features} />
                <Resource {...Permissions} />
                <Resource {...ActiveMarketEvents} />
                <Resource {...Notifications} />
                <Resource {...Configurations} />
                <Resource {...Settings} />
                <Resource {...Productions} />
                <Resource {...Performers} />
                <Resource {...Venues} />
                <Resource {...Inventory} />
                <Resource {...PricingGroups} />
                <Resource {...ProductionAssignments} />
                <Resource {...Schedules} />
                <Resource {...ProductionMappings} />
                <Resource {...VenueSectionMappings} />
                <Resource {...SectionMappings} />
                <Resource {...SourceProductionMappings} />
                <Resource {...ScheduleInstances} />
                <Resource {...NamespaceOverrides} />
                <Resource {...NamespaceLoggingDefaults} />
                <Resource {...StripeConfigurations} />
                <Resource {...BillingOrganizations} />
                <Resource {...BillingTransactions} />
                <Resource {...OrganizationPriceMode} options={{ id: 'Key' }} />
                <Resource {...PricerOrganizationInventoryAudits} />
                <Resource {...PricingGroupAudits} />
                <Resource {...PricingTemplateAudits} />
                <Resource {...ProductionAssignmentAudits} />
                <Resource {...OrganizationPricingDefaultsAudits} />
                <Resource {...OrganizationPricingSettingAudits} />
                <Resource {...ClientUserAudits} />
                <Resource {...OrganizationAudits} />
                <Resource {...UserInvitationAudits} />
                <Resource {...ResetPasswordAudits} />
                <Resource {...ChangedPasswordAudits} />
                <Resource {...PosOrganizationAudits} />
                <Resource {...PosCredentialAudits} />
                <Resource {...PricingTemplates} />
                <Resource {...OrgBusinessSummary} />
                <Resource {...VenueMappings} />
                <Resource {...PerformerMappings} />
                <Resource {...MailboxAssignments} />
                <Resource {...MessageTracking} />
            </Admin>
            <Footer />
        </>
    );
};

const App = () => {
    const { instance, accounts } = useMsal();

    return <AdminWrapper account={accounts[0]} instance={instance} />;
};

export default App;
