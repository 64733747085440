import {useEffect, useState} from "react";
import Box from "@mui/material/Box";

import {MenuProps, useSidebarState} from "react-admin";

import SubMenu from "./submenu";
import ClientUsers from "../resources/members";
import Invitations from "../resources/invitations";
import Organizations from "../resources/organizations";
import OrganizationRequests from "../resources/organizationrequests";
import Inventory from "../resources/inventory";
import Productions from "../resources/productions";
import Venues from "../resources/venues";
import Performers from "../resources/performers";
import Notifications from "../resources/notifications";
import Settings from "../resources/settings";
import Roles from "../resources/roles";
import Features from "../resources/features";
import ResourceMenuLink from "./resourceMenuLink";
import Permissions from "../resources/permissions";
import {
    FaCalendarAlt,
    FaCogs,
    FaCommentDollar,
    FaHotel,
    FaMailBulk,
    FaNetworkWired,
    FaTruckLoading,
    FaWpforms,
    FaMapMarkedAlt,
    FaFileInvoiceDollar,
    FaHistory
} from "react-icons/fa";
import Configurations from "../resources/configurations";
import PricingGroups from "../resources/pricing_groups";
import Schedules from "../resources/schedules";
import ProductionMappings from "../resources/productionMappings";
import ProductionAssignments from "../resources/production_assignments";
import NamespaceOverrides from "../resources/namespaceoverrides";
import NamespaceLoggingDefaults from "../resources/loggingdefaults";
import VenueSectionMappings from "../resources/venuesectionmappings";
import StripeConfigurations from "../resources/stripeConfigurations";
import BillingOrganizations from "../resources/billingOrganizations";
import OrganizationPriceMode from "../resources/organizationPriceMode";
import PricerOrganizationInventoryAudits from "../resources/pricerOrganizationInventoryAudits";
import PricingGroupAudits from "../resources/pricingGroupAudits";
import PricingTemplateAudits from "../resources/pricingTemplateAudits";
import ProductionAssignmentAudits from "../resources/productionAssignmentAudits";
import OrganizationPricingDefaultsAudits from "../resources/organizationPricingDefaultsAudits";
import OrganizationPricingSettingAudits from "../resources/organizationPricingSettingAudits";
import {useLocation} from "react-router-dom";
import {getEnvironment} from "../utils/helpers";
import ClientUserAudits from "../resources/clientUserAudits";
import OrganizationAudits from "../resources/organizationAudits";
import UserInvitationAudits from "../resources/userInvitationAudits";
import ResetPasswordAudits from "../resources/resetPasswordAudits";
import ChangedPasswordAudits from "../resources/changedPasswordAudits";
import PosOrganizationAudits from "../resources/posOrganizationAudits";
import PosCredentialAudits from "../resources/posCredentialAudits";
import SourceProductionMappings from "../resources/sourceProductionMappings";
import PricingTemplates from "../resources/pricingTemplates";
import OrgBusinessSummary from "../resources/orgBusinessSummary";
import VenueMappings from "../resources/venueMappings";
import PerformerMapping from "../resources/performerMappings";
import MailboxAssignments from "../resources/mailboxAssignments";
import BillingTransactions from "../resources/billingTransactions";
import MessageTracking from "../resources/messageTracking";

type MenuName =
    | "menuClient"
    | "menuInventory"
    | "menuNotifications"
    | "menuConfigurations"
    | "menuPricerEngine"
    | "menuScheduler"
    | "menuMapping"
    | "menuLogging"
    | "menuLogLevelDefaults"
    | "menuBilling"
    | "menuAudits"
    | "menuEmailParsing";

const Menu = ({dense = false}: MenuProps) => {
    const [state, setState] = useState({
        menuClient: true,
        menuInventory: true,
        menuNotifications: true,
        menuConfigurations: true,
        menuPricerEngine: true,
        menuScheduler: true,
        menuMapping: true,
        menuLogging: true,
        menuLogLevelDefaults: true,
        menuBilling: true,
        menuAudits: true,
        menuEmailParsing: true
    });
    const [open] = useSidebarState();
    const location = useLocation();
    

    const handleToggle = (menu: MenuName) => {
        setState((state) => ({...state, [menu]: !state[menu]}));
    };
    
    const env = getEnvironment();
    const formatTitle = (title:string) => {
        const prefix = env ? `[${env}]` : '';
        return `${prefix} ${title}`;
    }

    interface MenuTitleMap {
        [key: string]: string;
    }
    
    useEffect(() => {
        const menuTitleMap: MenuTitleMap  = {
            '/': `${env ? 'PRQX Admin Portal' : 'PRQX Admin'}`,
            '/organizations': "Organizations",
            '/organization-requests': "Organization Requests",
            '/invitations': "Invitations",
            '/members': "Members",
            '/roles': "Roles",
            '/features': "Features",
            '/permissions/org-permissions': "Permissions",
            '/notifications': "Notifications",
            '/configurations': "Configurations",
            '/settings': "App Settings",
            '/pos-inventory': "Org. Inventory",
            '/productions': "Productions",
            '/venues': "Venues",
            '/performers': "Performers",
            '/pricing-groups': "Pricing Groups",
            '/pricing-templates': "Pricing Templates",
            '/production-assignments': "Production Assignments",
            '/organization-pricing-setting': "Org. Price Setting",
            '/schedules': "Schedules",
            '/logging': "Logging",
            '/logging/defaults': "Logging Defaults",
            '/priority-production': "Priority Production",
            '/section-mapping-venue': "Section Mapping",
            '/source-production-mappings': "Production Mapping Review",
            '/stripe-configurations': "Stripe Configurations",
            '/billing-organizations': "Billing Organizations",
            '/billing-transactions': "Billing Transactions",
            '/pricer-organization-inventory-audits': "Pricer Org Inventory Audits",
            '/pricing-group-audits': "Pricing Groups Audits",
            '/pricing-template-audits': "Pricing Templates Audits",
            '/production-assignment-audits': "Production Assignments Audits",
            '/organization-pricing-defaults-audits': "Org Pricing Defaults Audits",
            '/organization-pricing-setting-audits': "Org Pricing Setting Audits",
            '/client-user-audits': "Client Users Audits",
            '/organization-audits': "Organizations Audits",
            '/user-invitation-audits': "User Invitations Audits",
            '/reset-password-audits': "Reset Passwords Audits",
            '/changed-password-audits': "Changed Passwords Audits",
            '/pos-organization-audits': "Pos Organizations Audits",
            '/pos-credential-audits': "Pos Credentials Audits",
            '/customer-provided-mappings': "Customer Provided Mappings",
            '/org-business-summary':'Org. Business Summary',
            '/source-performers' : 'Performer Mappings',
            '/source-venues': 'Venue Mappings',
            '/mailbox-assignments': 'Mailbox Assignments',
            '/message-tracking': 'Message Tracking',
        };

        const path = location.pathname;
        const title = formatTitle(menuTitleMap[path]) || 'PRQX Admin Portal';
        document.title = title;
    }, [location]);
    
    
    
    return (
        <Box
            sx={{
                width: open ? 250 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: (theme) =>
                    theme.transitions.create("width", {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <SubMenu
                handleToggle={() => handleToggle("menuClient")}
                isOpen={state.menuClient}
                name="Client Service"
                icon={<FaHotel/>}
                dense={dense}
            >
                <ResourceMenuLink resource={Organizations} dense={dense}/>
                <ResourceMenuLink resource={OrgBusinessSummary} dense={dense}/>
                <ResourceMenuLink resource={OrganizationRequests} dense={dense}/>
                <ResourceMenuLink resource={Invitations} dense={dense}/>
                <ResourceMenuLink resource={ClientUsers} dense={dense}/>
                <ResourceMenuLink resource={Roles} dense={dense}/>
                <ResourceMenuLink resource={Permissions} dense={dense}/>
                <ResourceMenuLink resource={Features} dense={dense}/>
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle("menuNotifications")}
                isOpen={state.menuNotifications}
                name="Notification Service"
                icon={<FaNetworkWired/>}
                dense={dense}
            >
                <ResourceMenuLink resource={Notifications} dense={dense}/>
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle("menuConfigurations")}
                isOpen={state.menuConfigurations}
                name="Config. Service"
                icon={<FaCogs/>}
                dense={dense}
            >
                <ResourceMenuLink resource={Configurations} dense={dense}/>
                <ResourceMenuLink resource={Settings} dense={dense}/>
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle("menuInventory")}
                isOpen={state.menuInventory}
                name="Inventory Service"
                icon={<FaTruckLoading/>}
                dense={dense}
            >
                <ResourceMenuLink resource={Inventory} dense={dense}/>
                <ResourceMenuLink resource={Productions} dense={dense}/>
                <ResourceMenuLink resource={Venues} dense={dense}/>
                <ResourceMenuLink resource={Performers} dense={dense}/>
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle("menuPricerEngine")}
                isOpen={state.menuPricerEngine}
                name="Pricer Service"
                icon={<FaCommentDollar/>}
                dense={dense}
            >
                <ResourceMenuLink resource={PricingGroups} dense={dense}/>
                <ResourceMenuLink resource={ProductionAssignments} dense={dense}/>
                <ResourceMenuLink resource={OrganizationPriceMode} dense={dense}/>
                <ResourceMenuLink resource={PricingTemplates} dense={dense}/>
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle("menuScheduler")}
                isOpen={state.menuScheduler}
                name="Scheduler"
                icon={<FaCalendarAlt/>}
                dense={dense}
            >
                <ResourceMenuLink resource={Schedules} dense={dense}/>
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle("menuLogging")}
                isOpen={state.menuLogging}
                name="Logging"
                icon={<FaWpforms/>}
                dense={dense}
            >
                <ResourceMenuLink resource={NamespaceOverrides} dense={dense}/>
                <ResourceMenuLink resource={NamespaceLoggingDefaults} dense={dense}/>
            </SubMenu>

            <SubMenu
                handleToggle={() => handleToggle("menuMapping")}
                isOpen={state.menuMapping}
                name="Mappings"
                icon={<FaMapMarkedAlt/>}
                dense={dense}
            >
                <ResourceMenuLink resource={ProductionMappings} dense={dense} />
                <ResourceMenuLink resource={VenueSectionMappings} dense={dense} />
                <ResourceMenuLink resource={SourceProductionMappings} dense={dense} />
                <ResourceMenuLink resource={PerformerMapping} dense={dense} />
                <ResourceMenuLink resource={VenueMappings} dense={dense} />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle("menuBilling")}
                isOpen={state.menuBilling}
                name="Billing"
                icon={<FaFileInvoiceDollar />}
                dense={dense}
            >
                <ResourceMenuLink resource={StripeConfigurations} dense={dense}/>
                <ResourceMenuLink resource={BillingOrganizations} dense={dense}/>
                <ResourceMenuLink resource={BillingTransactions} dense={dense}/>
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle("menuAudits")}
                isOpen={state.menuAudits}
                name="Audits"
                icon={<FaHistory />}
                dense={dense}
            >
                <ResourceMenuLink resource={PricerOrganizationInventoryAudits} dense={dense}/>
                <ResourceMenuLink resource={PricingGroupAudits} dense={dense}/>
                <ResourceMenuLink resource={PricingTemplateAudits} dense={dense}/>
                <ResourceMenuLink resource={ProductionAssignmentAudits} dense={dense}/>
                <ResourceMenuLink resource={OrganizationPricingDefaultsAudits} dense={dense}/>
                <ResourceMenuLink resource={OrganizationPricingSettingAudits} dense={dense}/>
                <ResourceMenuLink resource={ClientUserAudits} dense={dense}/>
                <ResourceMenuLink resource={OrganizationAudits} dense={dense}/>
                <ResourceMenuLink resource={UserInvitationAudits} dense={dense}/>
                <ResourceMenuLink resource={ResetPasswordAudits} dense={dense}/>
                <ResourceMenuLink resource={ChangedPasswordAudits} dense={dense}/>
                <ResourceMenuLink resource={PosOrganizationAudits} dense={dense}/>
                <ResourceMenuLink resource={PosCredentialAudits} dense={dense}/>
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle("menuEmailParsing")}
                isOpen={state.menuEmailParsing}
                name="Email Parsing"
                icon={<FaMailBulk />}
                dense={dense}>
                <ResourceMenuLink resource={MailboxAssignments} dense={dense}/>
                <ResourceMenuLink resource={MessageTracking} dense={dense}/>
            </SubMenu>
        </Box>
    );
};

export default Menu;
