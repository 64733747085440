import React, { ChangeEvent, useState } from "react";
import {
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  minLength, ReferenceArrayInput, AutocompleteArrayInput,
} from "react-admin";
import { externalTenantTypeChoices, organizationStatusChoices } from "./common";
import {
  ExternalTenantTypes,
  comparableMarketplaceSourceChoices, internationalPhoneParse,
} from "../../utils/constants";
import { email } from "ra-core";
import {CountryChoices} from "./POS/CountryChoices";
import {StateChoices} from "./POS/StateChoices";

interface FormData {
  organizationKey: string;
  externalTenantType: string;
}
export const OrganizationCreate = () => {

  const [showOrgKeyInput, setShowOrgKeyInput] = useState(false);

  const extTypesThatRequireManualKey = [ExternalTenantTypes.Skybox];

  const prefixMap: Record<string, string> = {};
  prefixMap[ExternalTenantTypes.Skybox] = "sb-";

  const handleExternalTenantTypeSelectChange = (
    event: ChangeEvent<{ name?: string; value: any }>
  ) => {
    // Check if a certain value was selected
    if (extTypesThatRequireManualKey.includes(event.target.value)) {
      setShowOrgKeyInput(true);
    } else {
      setShowOrgKeyInput(false);
    }
  };

  const transform = (data: FormData) => {
    data.organizationKey = `${prefixMap[data.externalTenantType]}${data.organizationKey}`
    return data;
};
  return (
  <Create transform={transform}>
    <SimpleForm>
     
      {showOrgKeyInput && (
        <TextInput source="organizationKey" required />
      )}
      <TextInput source="name" required />
      <TextInput source="knownAs" />
      <TextInput source="primaryContact" />
      <TextInput source="email" validate={email()} required />
      <TextInput
        source="phone"
        parse={internationalPhoneParse}
        validate={minLength(10)}
        required
      />
      <TextInput source="address1" />
      <TextInput source="address2" />
      <TextInput source="city" required />
      <StateChoices source="state" />
      <TextInput source="postalcode" required />
      <CountryChoices source="country"/>
      <SelectInput
        source="status"
        choices={organizationStatusChoices}
        required
      />
      <SelectInput
        source="comparableMarketplaceSource"
        choices={comparableMarketplaceSourceChoices}
        required
      />
      <SelectInput
        source="externalTenantType"
        choices={externalTenantTypeChoices}
        onChange={(event: any) => handleExternalTenantTypeSelectChange(event)}
        required
      />
      <TextInput source="lastModifiedBy" defaultValue="SYSTEM" disabled />
      <ReferenceArrayInput source="featureIds" reference="features">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
  )
}
