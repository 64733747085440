import {
  Datagrid,
  List,
  TextField,
  TextInput,
  EditButton,
  SelectArrayInput,
  FunctionField,
  useRecordContext,
  Button,
  useRefresh,
  useDataProvider,
  TopToolbar,
  FilterButton,
  ExportButton,
  HttpError,
  useNotify,
} from "react-admin";
import { styled } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import {
  OrganizationRequestStatuses,
} from "../../utils/constants";
import { format } from "date-fns";
import { organizationRequestStatusChoices } from "./common";
import {StateChoices} from "../Organizations/POS/StateChoices";
import React from "react";
import {CountryChoices} from "../Organizations/POS/CountryChoices";

const isApproved = (status: string): boolean => {
  return (
    status === OrganizationRequestStatuses.Rejected ||
    status === OrganizationRequestStatuses.Approved ||
    status === OrganizationRequestStatuses.Deleted
  );
};

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <ExportButton />
  </TopToolbar>
);
const DisabledEditButton = () => {
  const record = useRecordContext();

  const DisabledStyledEditButton = styled(EditButton)({
    pointerEvents: isApproved(record.status) ? "none" : "auto",
    opacity: isApproved(record.status) ? 0.5 : 1,
  });

  return <DisabledStyledEditButton />;
};

const ApproveButton = () => {
  const notify = useNotify();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const handleClick = async () => {
    try {
      await dataProvider.approveOrgRequest({
        data: {
          requestId: record.id,
          Status: OrganizationRequestStatuses.Approved,
        },
      });
      refresh();
    } catch (error) {
      if (error instanceof HttpError) {
        console.error("Error approving organization request:", error);
        const errorMessage = JSON.parse(error.body);
        notify(errorMessage, { type: "error" });
      }
    }
  };

  return (
    <Button
      label="Approve"
      onClick={handleClick}
      disabled={isApproved(record.status)}
    >
      <DoneIcon />
    </Button>
  );
};

const RejectButton = () => {
  const notify = useNotify();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const handleClick = async () => {
    try {
      await dataProvider.approveOrgRequest({
        data: {
          requestId: record.id,
          Status: "REJECTED",
        },
      });
      refresh();
    } catch (error) {
      if (error instanceof HttpError) {
        console.error("Error rejecting organization request:", error);
        const errorMessage = JSON.parse(error.body);
        notify(errorMessage, { type: "error" });
      }
    }
  };

  return (
    <Button
      label="Reject"
      onClick={handleClick}
      disabled={isApproved(record.status)}
    >
      <CloseIcon />
    </Button>
  );
};

const organizationFilter = [
  <TextInput label="Requester Name" source="requesterName" />,
  <TextInput label="Requester Email" source="requesterEmail" />,
  <TextInput label="Requester Phone" source="requesterPhone" />,
  <TextInput label="Company Name" source="companyName" />,
  <TextInput label="Known As" source="knownAs" />,
  <TextInput label="Company Address" source="companyAddress" />,
  <TextInput label="Company Phone" source="companyPhone" />,
  <StateChoices source="companyState" label="Company State" type="array"/>,
  <TextInput label="Company Postal Code" source="companyPostalCode" />,
  <CountryChoices source="companyCountry" type="array" label="Company Country"/>,
  <SelectArrayInput
    label="Status"
    source="status_eq"
    choices={organizationRequestStatusChoices}
    defaultValue={[OrganizationRequestStatuses.Pending]}
  />,
];

export const OrganizationRequestList = () => (
  <List
    filters={organizationFilter}
    actions={<ListActions />}
    filterDefaultValues={{ status_eq: [OrganizationRequestStatuses.Pending] }}
  >
    <Datagrid>
      <TextField source="requesterName" />
      <TextField source="requesterEmail" />
      <TextField source="requesterPhone" />
      <TextField source="companyName" />
      <TextField source="knownAs" />
      <TextField source="companyAddress" />
      <TextField source="companyPhone" />
      <TextField source="companyState" />
      <TextField source="companyCity" />
      <TextField source="companyPostalCode" />
      <TextField source="companyCountry" />
      <TextField source="status" />
      <FunctionField
        label="Last Updated"
        render={(record: { lastModifiedOn: any; lastModifiedBy: any }) =>
          `${format(
            new Date(record.lastModifiedOn),
            "MMMM do yyyy, h:mm:ss a"
          )} by ${record.lastModifiedBy}`
        }
      />
      <ApproveButton />
      <RejectButton />
      <DisabledEditButton />
    </Datagrid>
  </List>
);
