import React from "react";
import {
  Datagrid,
  List,
  TextField,
  TextInput,
  EditButton,
  FunctionField,
  ArrayField,
  SingleFieldList,
  useRecordContext,
} from "react-admin";
import Chip from "@mui/material/Chip";
import { format } from "date-fns";

const RoleChip: React.FC = () => {
  const record = useRecordContext();
  return <Chip label={record.name} size="small" />;
};
const featureFilter = [<TextInput label="Name" source="name" />];

export const FeatureList = () => (
  <List filters={featureFilter} >
    <Datagrid  bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="key" />
      <ArrayField source="roles" label="Roles">
              <SingleFieldList linkType={false}>
                <RoleChip />
              </SingleFieldList>
            </ArrayField>
      <FunctionField
        label="Last Updated"
        render={(record: { lastModifiedDate: any; lastModifiedBy: any }) =>
          `${format(
            new Date(record.lastModifiedDate),
            "MMMM do yyyy, h:mm:ss a"
          )} by ${record.lastModifiedBy}`
        }
      />
      <EditButton />
    </Datagrid>
  </List>
);
