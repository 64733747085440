import { BooleanInput, Edit, ReferenceInput, SelectInput, SimpleForm, TextInput } from "react-admin";
import { EditNoDeleteToolbar } from "../CustomToolbar/EditNoDeleteToolbar";
import { billingCountryChoices, billingStateChoices } from "../../utils/constants";
const configOptionRenderer = (choice: any) => `${choice.configKey} - ${choice.defaultOrganizationType}`;
export const BillingOrganizationEdit = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm toolbar={<EditNoDeleteToolbar />}>
            <TextInput source="organizationKey" disabled />
            <TextInput source="organizationName" disabled />
            <TextInput source="organizationExternalId" label="External Id" disabled />
            <TextInput source="subscriptionId" label="Subscription Id" />
            <TextInput source="subscriptionItemId" label="Subscription Item Id" />
            <TextInput source="email" label="Billing Email" />
            <ReferenceInput source="configKey" reference="stripe-configurations" required sort={{field: "configKey", order: "DESC"}}>
                <SelectInput optionText={configOptionRenderer} optionValue="configKey" required/>
            </ReferenceInput>
            <BooleanInput source="isBillingActive" label="Is Billing Active?" />
            <TextInput source="address1" label="Address 1" />
            <TextInput source="address2" label="Address 2" />
            <TextInput source="city" label="City" />
            <SelectInput source="state" label="State" choices={billingStateChoices} />
            <TextInput source="postalCode" label="Postal Code" />
            <SelectInput source="country" label="Country" choices={billingCountryChoices} />
        </SimpleForm>
    </Edit>
);