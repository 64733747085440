import {styled} from "@mui/material/styles";
import {Datagrid} from "react-admin";

export const InventoryGrid = styled(Datagrid)({
    '& .RaDatagrid-row': {
        height: '.45rem',
    },
    '& .MuiTableHead-root .MuiTableCell-root': {
        fontSize: '0.75rem !important',
        padding: '0.275rem 0.4rem',
    },
    '& .MuiTableCell-root': {
        padding: '0.275rem 0.4rem',
        fontSize: '0.75rem !important',
    },
    '& .MuiTableCell-root .MuiTypography-root': {
        fontSize: '0.75rem !important',
    },
});