import React from "react";
import {
  SimpleForm,
  TextInput,
  Create,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";

export const FeatureCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" required />
      <TextInput source="key" required />
      <ReferenceArrayInput source="roleIds" reference="roles">
                    <AutocompleteArrayInput optionText="name" />
                  </ReferenceArrayInput>
      <TextInput source="lastModifiedBy" defaultValue="SYSTEM" disabled />
    </SimpleForm>
  </Create>
);
