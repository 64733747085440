
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import React from "react";
import TableBody from "@mui/material/TableBody";
import {Typography} from "@mui/material";
import {format, parseISO} from "date-fns";


export const AdditionalInfo = (recordContainer:any) => {
    
    
    
    const record = recordContainer.record;  
    
    const {id, createdDate, lastModifiedBy, lastModifiedDate} = record;
    
    const createdDateFormatted = format(parseISO(createdDate), "EEEE MMMM d, yyyy h:mm a");
    
    const lastModifiedDateFormatted = format(parseISO(lastModifiedDate), "EEEE MMMM d, yyyy h:mm a");
    
    
    return (

        <Card sx={{margin: "0.2rem", padding: "0.5rem"}}>
            <Typography variant={"h6"} sx={{margin: "0.5rem"}}>Additional Info</Typography>
            
                <>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Created Date</TableCell>
                                    <TableCell align="left">Last Modified By</TableCell>
                                    <TableCell align="left">Last Modified Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                    <TableRow key={id}>
                                        <TableCell align="left">{createdDateFormatted}</TableCell>
                                        <TableCell align="left">{lastModifiedBy}</TableCell>
                                        <TableCell align="left">{lastModifiedDateFormatted}</TableCell>
                                    </TableRow>
                               
                            </TableBody>
                        </Table>
                </>
            
        </Card>
    );
};