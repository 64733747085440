import {
    Button,
    Datagrid,
    HttpError,
    List, SelectArrayInput,
    TextField,
    useDataProvider,
    useNotify,
    useRecordContext,
    useRefresh
} from "react-admin";
import { FaRedoAlt } from "react-icons/fa";
import { MessageTrackingStatus} from "../../utils/constants";
import CustomOrganizationInput from "../Members/CustomOrganizationInput";
import React from "react";
import {messageTrackingStatusChoices} from "./common";
import {lowerCaseCompare} from "../../utils/helpers";

const defaultFilterValue = [
    MessageTrackingStatus.Received,
    MessageTrackingStatus.Identified,
    MessageTrackingStatus.Published,
    MessageTrackingStatus.Unidentified,
    MessageTrackingStatus.Unparsable
];

const filters = [
    <CustomOrganizationInput label={"Organization"} source="organizationKey" resourceToFetch="organizations" alwaysOn />,
    <SelectArrayInput
        label="Status"
        source="status_eq"
        choices={messageTrackingStatusChoices}
        defaultValue={defaultFilterValue}
    />,

];

const CanNotReprocess = (status: string): boolean => {
    return (
        lowerCaseCompare(status, MessageTrackingStatus.Parsed) ||
        lowerCaseCompare(status, MessageTrackingStatus.Received)
    );
};

const ReprocessMessage = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    const handleClick = async () => {
        try {
            await dataProvider.reprocessMessage({ data: record });
            notify(`Reprocess Request Sent`, { type: "success" });
            refresh();
        } catch (error) {
            if (error instanceof HttpError) {
                console.error("Error sending request:", error);
                notify("Error sending request. See console for details", { type: "error" });
            }
        }
    };

    return (
        <Button 
            onClick={handleClick}
            disabled={CanNotReprocess(record.status)}
        >
            <FaRedoAlt />
        </Button>
    );
};
export const MessageTrackingList = () => (
    <List
        filters={filters}
        sort={{ field: "organizationKey", order: "ASC" }}>
        <Datagrid>
            <TextField source="organizationKey" label="Organization Key" />
            <TextField source="emailAddress" label="Email Address" />
            <TextField source="messageId" label="Message ID" />
            <TextField source="blobPathUri" label="BlobPathUri" />
            <TextField source="status" label="Status" />
            <ReprocessMessage />
        </Datagrid>
    </List>
);