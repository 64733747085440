export const formatDateWithoutTZ = (value: string) => {
  return value ? new Date(value).toISOString().slice(0, 16) : "";
};

export const parseDateToUTC = (value: string) => {
  return value ? new Date(value + "Z").toISOString() : ""; // Append 'Z' to treat the value as UTC
};

export const getEnvironment = () => {
  const domain = window.location.host;
  const pattern = "admin.([a-zA-Z]+)?.pricerqx.com";
  const match = domain.match(pattern);
  if (match && match[1]) {
    return match[1]?.toUpperCase();
  } else {
    return null;
  }
};

export const lowerCaseCompare = (a: string, b: string) : boolean => {
  return a.toLowerCase() === b.toLowerCase();
}

export const isPositiveInteger = (string: string) => {
  const number = Number(string);
  const isInteger = Number.isInteger(number);
  const isPositive = number > 0;

  return isInteger && isPositive;
}

export const prodFilterToQuery = (searchText: string) => {
  return isPositiveInteger(searchText) ? { id: searchText } : { productionName: searchText };
};

export const venueFilterToQuery = (searchText: string) => {
  return isPositiveInteger(searchText) ? { id: searchText } : { name: searchText };
};

export const performerFilterToQuery = (searchText: string) => {
  return isPositiveInteger(searchText) ? { id: searchText } : { name: searchText };
};

export const displayAsMoney = (value: number) => {
  return value ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '';
}