import {SelectArrayInput, SelectInput, useDataProvider} from "react-admin";
import React, {FC, useEffect, useState} from "react";
import {CustomFieldProps} from "../../Common/CustomFieldProps";


export const StateChoices: FC<CustomFieldProps> = ({source = "state", label = "State", type}) => {
    const dataProvider = useDataProvider();
    const [stateChoices, setstateChoices] = useState([] as any);
    useEffect(() => {
        const fetchStates = async () => {
            const resp = await dataProvider.getStates();
            const states = resp.json.value;
            const statesParsed = JSON.parse(states);
            
            setstateChoices(statesParsed);
        }
        fetchStates();
    },[dataProvider]);
    return (
        type == "array" ? 
            <SelectArrayInput label={label} source={source} choices={stateChoices} />   
            :
            <SelectInput source={source} label={label} choices={stateChoices} required />
    );
    }