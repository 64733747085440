import React from "react";
import {
  Datagrid,
  List,
  TextField,
  TextInput,
  EditButton,
  SelectArrayInput,
  FunctionField,
  useRecordContext,
  Link, SingleFieldList, ArrayField, ReferenceArrayInput,
} from "react-admin";
import {
  OrganizationStatuses,
  comparableMarketplaceSourcesChoices,
  externalTenantTypesChoices,
} from "../../utils/constants";
import { format } from "date-fns";
import { organizationStatusChoices } from "./common";
import Chip from "@mui/material/Chip";
import Impersonate from "../Common/Impersonate";
import {StateChoices} from "./POS/StateChoices";

const ViewUsers = () => {
  const record = useRecordContext();
  return (
    <Link
      to={{
        pathname: `/members`,
        search: `filter=${JSON.stringify({ organizationId: [record.id] })}`,
      }}
      onClick={(event) => event.stopPropagation()}
    >
      View Users
    </Link>
  );
};


const FeatureChip: React.FC = () => {
  const record = useRecordContext().toString();
  return <Chip label={record} size="small" />;
};

const ViewInventory = () => {
  const record = useRecordContext();
  return (
    <Link
      to={{
        pathname: `/pos-inventory`,
        search: `filter=${JSON.stringify({
          organizationKey: [record.organizationKey],
        })}`,
      }}
      onClick={(event) => event.stopPropagation()}
    >
      View Inventory
    </Link>
  );
};
const InviteUsers = () => {
  const record = useRecordContext();

  return (
    <Link
      to={{
        pathname: "/invitations/create?organizationId=" + record.id,
      }}
      onClick={(event) => event.stopPropagation()}
    >
      Invite Users
    </Link>
  );
};
const defaultFilterValue = [
  OrganizationStatuses.Active,
  OrganizationStatuses.Inactive,
  OrganizationStatuses.Pending,
];
const organizationFilter = [
  <TextInput label="Name" source="name" />,
  <TextInput label="Known As" source="knownAs" />,
  <TextInput label="Email" source="email" />,
  <TextInput label="PrimaryContact" source="primaryContact" />,
  <TextInput label="Phone" source="phone" />,
  <TextInput label="City" source="city" />,
  <StateChoices source="state" type="array"/>,
  <SelectArrayInput
    label="Status"
    source="status_eq"
    choices={organizationStatusChoices}
    defaultValue={defaultFilterValue}
  />,
  <SelectArrayInput
    label="Comparables Marketplace"
    source="comparableMarketplaceSource"
    choices={comparableMarketplaceSourcesChoices}
  />,
  <SelectArrayInput
    label="External Tenant Type"
    source="externalTenantType"
    choices={externalTenantTypesChoices}
  />,
  <ReferenceArrayInput reference="features" source="features_in" label="Features">
    <SelectArrayInput optionText="name" label="Features"/>
  </ReferenceArrayInput>
];

export const OrganizationList = () => (
  <List
    filters={organizationFilter}
    filterDefaultValues={{ status_eq: defaultFilterValue }}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="knownAs" />
      <TextField source="primaryContact" />
      <TextField source="email" />
      <TextField source="phone" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="status" />
      <TextField source="organizationKey" />
      <TextField source="comparableMarketplaceSource" />
      <TextField source="externalTenantType" />
      <FunctionField
        label="Last Updated"
        render={(record: { lastModifiedOn: any; lastModifiedBy: any }) =>
          `${format(
            new Date(record.lastModifiedOn),
            "MMMM do yyyy, h:mm:ss a"
          )} by ${record.lastModifiedBy}`
        }
      />
      <ArrayField source="featureIdNames" label="Features" sortable={false}>
        <SingleFieldList linkType={false}>
          <FeatureChip />
        </SingleFieldList>
      </ArrayField>
      <Impersonate />
      <ViewUsers />
      <ViewInventory />
      <EditButton />
      <InviteUsers />
    </Datagrid>
  </List>
);
