import {
  Edit,
  SimpleForm,
  TextInput,
  minLength,
} from "react-admin";
import {
  phoneNumberFormat,
} from "../../utils/constants";
import { email } from "ra-core";
import { EditToolbar } from "../CustomToolbar/EditToolbar";
import {StateChoices} from "../Organizations/POS/StateChoices";
import React from "react";
import {CountryChoices} from "../Organizations/POS/CountryChoices";

export const OrganizationRequestEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="requesterName" required />
      <TextInput source="requesterEmail" validate={email()} required />
      <TextInput
        source="requesterPhone"
        parse={phoneNumberFormat}
        validate={minLength(14)}
      />
      <TextInput source="companyName" required />
      <TextInput source="companyAddress" />
      <TextInput source="knownAs" />
      <TextInput
        source="companyPhone"
        parse={phoneNumberFormat}
        validate={minLength(14)}
      />
      <StateChoices source="companyState" label="Company State"/>,
      <TextInput source="companyCity" />
      <TextInput source="companyPostalCode" />
      <CountryChoices source="companyCountry" label="Company Country"/>,
      <TextInput source="lastModifiedBy" defaultValue="SYSTEM" disabled />
    </SimpleForm>
  </Edit>
);
